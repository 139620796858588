.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 10rem;
  width: 100%;

  .triangle {
    position: relative;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;

    & div {
      border-radius: 100%;
      height: 20px;
      width: 20px;
      background-color: #fff;
      -webkit-animation: follow 1.8s ease-in-out infinite;
      animation: follow 1.8s ease-in-out infinite;
      position: absolute;
      top: 0;
      -webkit-transform: translateX(-30px);
      -ms-transform: translateX(-30px);
      transform: translateX(-30px);
    }

    & div:nth-child(1) {
      -webkit-animation-delay: 1.2s;
      animation-delay: 1.2s;
      background-color: #8c78fe;
    }

    & div:nth-child(2) {
      -webkit-animation-delay: 0.6s;
      animation-delay: 0.6s;
      background-color: #77d8ff;
    }

    & div:nth-child(3) {
      background-color: #77ffb0;
    }
  }
}

@-webkit-keyframes follow {
  0% {
    -webkit-transform: translatex(-30px);
    transform: translatex(-30px);
  }
  33% {
    -webkit-transform: translatex(30px);
    transform: translatex(30px);
  }
  66% {
    -webkit-transform: translatex(0) translatey(-40px);
    transform: translatex(0) translatey(-40px);
  }
  100% {
    -webkit-transform: translatex(-30px) translatey(0);
    transform: translatex(-30px) translatey(0);
  }
}

@keyframes follow {
  0% {
    -webkit-transform: translatex(-30px);
    transform: translatex(-30px);
  }
  33% {
    -webkit-transform: translatex(30px);
    transform: translatex(30px);
  }
  66% {
    -webkit-transform: translatex(0) translatey(-40px);
    transform: translatex(0) translatey(-40px);
  }
  100% {
    -webkit-transform: translatex(-30px) translatey(0);
    transform: translatex(-30px) translatey(0);
  }
}
